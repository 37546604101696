@font-face {
    font-family: "Sisco";
    src: url('../../assets/fonts/sisco/Sisco.otf');
}
@font-face {
    font-family: "Sisco";
    src: url('../../assets/fonts/sisco/Sisco-Bold.otf');
    font-weight: bold;
}
@font-face {
    font-family: "Sisco";
    src: url('../../assets/fonts/sisco/Sisco-Italic.otf');
    font-style: italic;
}
@font-face {
    font-family: "Sisco";
    src: url('../../assets/fonts/sisco/Sisco-BoldItalic.otf');
    font-weight: bold;
    font-style: italic;
}