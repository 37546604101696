.mat-header-cell {
    @apply text-default #{'!important'};
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type {
    @apply pl-4 #{'!important'};
}

tr.mat-header-row {
    height: 40px !important;
}

tr.mat-row,
tr.mat-footer-row {
    height: 37px !important;
}

th.rui-header-cell:first-of-type {
    padding-left: 0 !important;

    [rui-header-cell-label],
    [rui-header-cell-filter] {
        padding-left: 1rem;
    }
}

th.rui-header-cell:last-of-type {
    padding-right: 0 !important;

    [rui-header-cell-label],
    [rui-header-cell-filter] {
        padding-right: 24px;
    }
}

th.rui-header-cell {
    [rui-header-cell-filter] {
        .mat-form-field {
            @apply w-full pr-4;
            &.mat-form-field-appearance-fill .mat-form-field-wrapper {
                margin-bottom: 0;

                .mat-form-field-subscript-wrapper {
                    display: none !important;
                    height: 0 !important;
                }
            }

            &:not(.mat-form-field-has-label) .mat-form-field-wrapper {
                .mat-form-field-flex {
                    min-height: 32px;
                }

                .mat-form-field-infix .mat-input-element {
                    padding: 6px 0;
                }
            }
        }
    }
}

td[rui-table-loader-col]:first-of-type {
    div {
        @apply pl-4;
    }
}
