.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.mat-button {
    transition: background-color 200ms linear;
}

[buttons-sm] {
    @apply text-sm;
    padding: 0 8px !important;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
}

[buttons-lg] {
    padding: 0 22px !important;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
}
