// ===================== Fuse-theme =====================
@use '~libs/portal/ui';
// ===================== Quill =====================
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

* {
    font-family: Sisco, Arial, Helvetica, sans-serif;
}

// todo: cleanup in the future
.table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    table.mat-table {
        border-collapse: separate;
        width: 100%;
        white-space: nowrap;

        tr {
            height: 56px; // to show "no results" row
            th,
            td {
                vertical-align: middle;
                padding: 0 24px;
                &.paddingless {
                    padding: 0;
                }
                &.align-center-col {
                    &.mat-header-cell,
                    &.mat-cell {
                        text-align: center;
                    }
                }
                &.action-col-shadow {
                    z-index: 2 !important;
                    box-shadow: 0 0.5rem 0.5em rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
    .mat-paginator {
        z-index: 3 !important;
    }
}

/* Loading */
.example-container {
    position: relative;
}

.example-table-container {
    position: relative;
    background-color: white;
}

.example-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* This is a default style for angular material. For some reason though, it sometimes didn't get applied to datetime-picker.
When not applied, after opening the calendar and then navigating to another month with more rows of days,
the calendar could clip outside of the screen. For this reason, it is duplicated here so it would be applied consistently. */
.mat-datepicker-content .mat-calendar {
    height: 354px !important;
}

.mat-tooltip.tooltip {
    font-size: 14px !important;
}
