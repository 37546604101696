.fuse-vertical-navigation-item {
    .fuse-vertical-navigation-item-title-wrapper {
        .fuse-vertical-navigation-item-title {
            @apply opacity-100 text-link-600 dark:text-link-400 #{'!important'};
        }
    }
}

.fuse-vertical-navigation-item-active {
    .fuse-vertical-navigation-item-title-wrapper {
        .fuse-vertical-navigation-item-title {
            @apply text-default #{'!important'};
        }
    }
}
